import { v4 } from 'uuid';
import TYPES from '@/types';

// Application
import GetCustomGoalTypesQuery
  from '@/modules/flagship/catalogs/custom-goal-types/application/queries/get-custom-goal-types-query';
import GetInvestorProfileQuery
  from '@/modules/flagship/investor-profile/investor-profile/application/queries/get-investor-profile-query';
import GetCustomInvestorGoalSearchByCustomerQuery
  from '@/modules/flagship/custom-investor-goal/application/query/get-custom-investor-goal-search-by-customer-query';
import UpdateInvestorProfileCommand
  from '@/modules/flagship/investor-profile/investor-profile/application/commands/update-investor-profile-command';
import UpdateCustomInvestorGoalNameCommand
  from '@/modules/flagship/custom-investor-goal/application/command/update-custom-investor-goal-name-command';

// Domain
import {
  CustomGoalTypesEntity,
} from '@/modules/flagship/catalogs/custom-goal-types/domain/entities/custom-goal-types-entity';
import {
  CustomInvestorGoalNameDto,
} from '@/modules/flagship/custom-investor-goal/domain/dtos/custom-investor-goal-name-dto';
import {
  InvestorProfileDto,
} from '@/modules/flagship/investor-profile/investor-profile/domain/dtos/investor-profile-dto';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { maxLengthGoalNameRule, requiredRule } from '@/vue-app/utils/form-rules';

export default class EditNameCustomizedGoalModalViewModel {
  @Inject(TYPES.GET_INVESTOR_PROFILE_QUERY)
  private readonly get_investor_profile_query!: GetInvestorProfileQuery;

  @Inject(TYPES.GET_CUSTOM_INVESTOR_GOAL_SEARCH_BY_CUSTOMER_QUERY)
  private readonly get_search_by_customer_query!: GetCustomInvestorGoalSearchByCustomerQuery;

  @Inject(TYPES.GET_CUSTOM_GOAL_TYPES_QUERY)
  private readonly get_custom_goal_type!: GetCustomGoalTypesQuery;

  @Inject(TYPES.UPDATE_INVESTOR_PROFILE_COMMAND)
  private readonly update_investor_profile_command!: UpdateInvestorProfileCommand;

  @Inject(TYPES.UPDATE_CUSTOM_INVESTOR_GOAL_NAME_COMMAND)
  private readonly update_custom_goal_command!: UpdateCustomInvestorGoalNameCommand;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  readonly i18n_namespace = 'components.goals-dashboard.edit-name-customized-goal';

  is_loading = false;

  custom_goal_types: Array<CustomGoalTypesEntity> = [];

  custom_goal_label = '';

  custom_goal_name = '';

  custom_goal_icon_name = '';

  input_rules = {
    other_goal_name: [requiredRule, (value: string) => maxLengthGoalNameRule(26, value)],
  };

  private custom_investor_goal_dto: CustomInvestorGoalNameDto = {
    id: '',
    goal_name: '',
    custom_goal_type: {
      id: '',
      icon_name: '',
      name: '',
      label: '',
      description: '',
      updated_at: '',
      created_at: '',
    },
  }

  show_success_modal = false;

  show_open_edit_image_modal = false;

  custom_base64_cropped_image = '';

  selected_icon_name = '';

  customer_investor_goal_id = '';

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  initialize = async (custom_investor_goal_id: string) => {
    this.customer_investor_goal_id = custom_investor_goal_id;
    this.setDefaultValuesToCustomerInvestorGoalDto();
    await this.loadCustomGoalTypes();
    await this.loadCustomInvestorGoal(custom_investor_goal_id);
  }

  get is_continue_btn_disabled() {
    return (this.custom_goal_label === '') || !(this.custom_goal_label.length < 26);
  }

  loadCustomGoalTypes = async () => {
    try {
      const custom_goal_types = await this.get_custom_goal_type.execute();
      this.custom_goal_types = (custom_goal_types.filter(
        (item) => item.name !== 'emergency_fund'
          && item.name !== 'build_my_retirement_fund',
      ));
      this.custom_goal_types.push({
        id: '415e1f05-e3ef-458e-a6aa-55627e66bd58',
        name: 'custom',
        label: 'Personalizada',
        description: 'Personalizada',
        icon_name: 'icon-add.svg',
        created_at: '',
        updated_at: '',
      });
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_custom_goal_types'));
    }
  };

  loadCustomInvestorGoal = async (custom_investor_goal_id: string) => {
    try {
      const custom_investor_goal_a = await this.get_search_by_customer_query.execute(true);

      const custom_investor_goal = custom_investor_goal_a.filter(
        (goal) => goal.id === custom_investor_goal_id,
      );
      this.custom_goal_label = custom_investor_goal[0].goal_name;
      this.custom_goal_name = custom_investor_goal[0].custom_goal_type.name;
      this.custom_goal_icon_name = custom_investor_goal[0].custom_goal_type.icon_name;
      this.selected_icon_name = custom_investor_goal[0].custom_goal_type.icon_name;
      this.custom_investor_goal_dto.goal_name = custom_investor_goal[0].goal_name;
      this.custom_investor_goal_dto.custom_goal_type = custom_investor_goal[0].custom_goal_type;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_custom_investor_goal'));
    }
  }

  setCustomGoal = async () => {
    if (this.custom_goal_name !== 'budget') {
      const custom_goal_value = (this.custom_goal_types.find(
        (item) => item.name === this.custom_goal_name,
      ));

      this.setDefaultValuesToCustomerInvestorGoalDto();
      this.custom_investor_goal_dto.custom_goal_type = {
        created_at: custom_goal_value!.created_at,
        description: custom_goal_value!.description,
        id: custom_goal_value!.id,
        name: custom_goal_value!.name,
        updated_at: custom_goal_value!.updated_at,
        label: this.custom_goal_label,
        icon_name: this.custom_goal_icon_name,
      };
    }

    this.custom_investor_goal_dto.goal_name = this.custom_goal_label;

    if (this.custom_goal_icon_name === 'icon-add.svg' && this.custom_base64_cropped_image) {
      const mime_type = this.custom_base64_cropped_image.split(';')[0];
      this.custom_investor_goal_dto.custom_icon = {
        file_id: this.custom_investor_goal_dto.id,
        mime_type: mime_type.split(':')[1],
        file_data: this.custom_base64_cropped_image,
      };
    }

    await this.updateCustomInvestorGoal();
  }

  updateCustomInvestorGoal = async () => {
    try {
      this.is_loading = true;
      await this.update_custom_goal_command.execute(this.custom_investor_goal_dto);
      this.is_loading = false;
      this.show_success_modal = true;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.update_custom_investor_goal'));
    }
  };

  setDefaultValuesToCustomerInvestorGoalDto = () => {
    this.custom_investor_goal_dto = {
      id: this.customer_investor_goal_id,
      goal_name: '',
      custom_goal_type: {
        id: '',
        icon_name: '',
        name: '',
        label: '',
        description: '',
        updated_at: '',
        created_at: '',
      },
    };
  }

  accept = async () => {
    window.location.href = '/goals-dashboard';
  }

  openEditImageModal = (btn_name: string) => {
    if (btn_name === 'custom') {
      this.show_open_edit_image_modal = true;
    }
  }

  closeEditIMageModal = () => {
    this.show_open_edit_image_modal = false;
    this.verifyIfUserSelectACustomImage();
  }

  setBase64CustomImage = (base64_image: string) => {
    this.custom_base64_cropped_image = base64_image;
  }

  verifyIfUserSelectACustomImage = () => {
    this.custom_goal_icon_name = 'icon-add.svg';
    if (!this.custom_base64_cropped_image) {
      this.custom_goal_icon_name = this.selected_icon_name;
    }
  }

  showCustomImage = (icon_name: string) => icon_name === 'icon-add.svg' && this.custom_base64_cropped_image
}
